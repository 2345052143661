import React, { useState } from 'react'
import Head from 'next/head'
import styled from 'styled-components'
import {
  CloseButton,
  Button,
  Flex,
  Link,
  Text,
  Box,
  getPaletteColor,
  Image
} from 'pcln-design-system'
import type { Referral } from '@/types'
import useBootstrapData from '@/hooks/useBootstrapData'

const COOKIE_MAX_AGE = 2592000 // 30 Days

const Container = styled(Flex)`
  background-color: ${getPaletteColor('background.base')};
  color: ${getPaletteColor('text.lightest')};
  padding: 8px;
  @media (min-width: 400px) {
    padding-right: 24px;
  }
`

const StyledLink = styled(Link)`
  flex: 1 1 auto;
  display: flex;
  &:active,
  &:focus,
  &:visited,
  &:hover {
    color: ${getPaletteColor('text.lightest')};
    text-decoration: none;
  }
`

const appIconUrl =
  'https://s1.pclncdn.com/design-assets/brand-assets/app-icon.svg'

type AppBannerProps = {
  titleText: string
  contentText: string
  pageId: string
  referral: Referral
  clientGUID: string
}

export default function AppBanner({
  titleText,
  contentText,
  pageId,
  referral,
  clientGUID
}: AppBannerProps) {
  const [isBannerVisible, setBannerVisible] = useState(true)

  const closeAppBanner = () => {
    setBannerVisible(false)
    document.cookie = `hideAppBanner=true; domain=.priceline.com; path=/; max-age=${COOKIE_MAX_AGE};`
  }

  const impressionUrl = [
    'https://imp.control.kochava.com/track/impression?campaign_id=koiospriceline388053593d51c5580c1da575ac62c7&network_id=1017',
    `&PAGEID=IOS_SMARTBANNER`,
    `&REFID=${referral?.sourceId ?? ''}${referral?.id ?? ''}`,
    `&REFCLICKID=${referral?.clickId ?? ''}`,
    `&SUB_SITE_ID=${clientGUID}&site_id=1`
  ].join('')
  const downloadAppLink = `https://www.priceline.com/r/app/?channel=Display&product=App&theme=app_dl_install\
    &PAGEID=${pageId}_v5\
    &sub_site_id=${clientGUID}\
    &refid=${referral?.id ?? ''}\
    &refclickid=${referral?.clickId ?? ''}
  `
  const { isMobile, isIOS } = useBootstrapData()

  if (isMobile && !!isIOS) {
    return (
      <>
        <Head>
          <meta
            name="apple-itunes-app"
            content="app-id=336381998"
            key="apple-smart-banner"
          />
        </Head>
        <Image src={impressionUrl} height="0" width="1" />
      </>
    )
  }

  return isBannerVisible ? (
    <Container
      id="app-download-banner"
      className="app-banner"
      m={1}
      borderRadius="lg"
    >
      <Flex alignItems="center" mr={12}>
        <CloseButton
          animate={false}
          style={{ color: '#0068ef' }}
          size={24}
          data-ga-category="PCLN_HOME"
          data-ga-action="App_Banner"
          data-ga-label="dismiss"
          title="dismiss app banner"
          onClick={closeAppBanner}
        />
      </Flex>
      <StyledLink
        color="text.lightest"
        href={downloadAppLink}
        data-testid="DASH_MWEB_TAB_GET_APP"
        data-ga-category="PCLN_HOME"
        data-ga-action="App_Banner"
        data-ga-label="click to launch"
      >
        <Flex alignItems="flex-start" pr={12} py={0} width={52}>
          <Image src={appIconUrl} width="40" height="40" alt="Priceline Icon" />
        </Flex>
        <Flex flexDirection="column" justifyContent="center">
          <Text
            fontWeight="bold"
            textStyle="captionBold"
            color="background.darkest"
          >
            {titleText}
          </Text>
          <Text textStyle="disclaimer" color="background.dark">
            {contentText}
          </Text>
        </Flex>
        <Flex alignItems="center" ml="auto">
          <Box ml="auto" my="auto" pl={1}>
            <Button size="small">Get</Button>
          </Box>
        </Flex>
      </StyledLink>
    </Container>
  ) : null
}
