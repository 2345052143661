import type { EnumLiteralsOf } from '@/types'
import useBootstrapData from '@/hooks/useBootstrapData'

const ProductIds = {
  hotels: 5,
  flights: 1,
  packages: 17,
  home: 16
} as const

export type ValidTmsProductCodes = keyof typeof ProductIds

type ProductIdNumbers = EnumLiteralsOf<typeof ProductIds>

type TmsData = {
  appCode: 'MOBILEWEB' | 'DESKTOP'
  sha2e?: string
  pageType?: 'hp' | 'prodhp'
  productId?: ProductIdNumbers
  refId: string
  refClickId: string
  SETI: Record<string, string>
  firstTimeHere: boolean
  startOfVisit: boolean
  vipTier: string
  vipMember: 'Yes' | 'No'
}

export default function useTmsConfig(
  product: ValidTmsProductCodes
): TmsData | null {
  const {
    isMobile,
    referral,
    seti,
    webstats,
    sha2e,
    vipTier = ''
  } = useBootstrapData()
  const { clickId = '', id = '', sourceId = '' } = referral
  const { firstTimeHere, startOfVisit } = webstats

  const tmsData: TmsData = {
    appCode: isMobile ? 'MOBILEWEB' : 'DESKTOP',
    sha2e,
    refId: sourceId + id,
    refClickId: clickId,
    SETI: seti,
    firstTimeHere,
    startOfVisit,
    vipTier: vipTier || 'NONE',
    vipMember: vipTier ? 'Yes' : 'No'
  }
  return {
    ...tmsData,
    pageType: product === 'home' ? 'hp' : 'prodhp',
    productId: ProductIds[product]
  }
}
