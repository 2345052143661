import { useRouter } from 'next/router'
import { useFeature } from '@pcln/feature-components'
import isLoadedInAppView from '@/shared-utils/isLoadedInAppView'
import useBootstrapData from '@/hooks/useBootstrapData'
import type { QueryString, Referral } from '@/types'

const getTrafficInfo = (query: QueryString, referral: Partial<Referral>) => {
  const {
    id: _ckReferralId = '',
    clickId: cookieRefClickId = '',
    sourceId: _ckRefSourceId = ''
  } = referral
  const cookieRefId = _ckRefSourceId.concat(_ckReferralId)

  const { refid: _qsRefId = '', refclickid: _qsRefClickId = '' } = query
  const queryRefId =
    typeof _qsRefId === 'string' && Object.keys(_qsRefId).length > 0
      ? _qsRefId
      : ''
  const queryRefClickId =
    typeof _qsRefClickId === 'string' && Object.keys(_qsRefClickId).length > 0
      ? _qsRefClickId
      : ''

  const isPaid =
    cookieRefId.indexOf('GOOGLECPC|MSN') > -1 ||
    queryRefId.indexOf('GOOGLECPC|MSN') > -1

  const isBranded =
    cookieRefClickId.indexOf('BRAND') > -1 ||
    queryRefClickId.indexOf('BRAND') > -1

  const excludedRefIds = ['PLHTLCOMBINED', 'PLHBC10161OPQ']
  const isBlackListed =
    cookieRefId.indexOf('CO') === 0 ||
    queryRefId.indexOf('CO') === 0 ||
    excludedRefIds.indexOf(cookieRefId) > -1 ||
    excludedRefIds.indexOf(queryRefId) > -1

  return {
    isPaid,
    isBranded,
    isBlackListed
  }
}

const useAppBanner = () => {
  const { query } = useRouter()
  const bootstrapData = useBootstrapData()
  const {
    isCookieSetToHideAppBanner,
    isMobile = false,
    referral
  } = bootstrapData
  const { isPaid, isBranded, isBlackListed } = getTrafficInfo(query, referral)
  const isAppBannerEnabled = useFeature('siteExperience.appBannerEnabled')

  const hideAppBanner =
    !isAppBannerEnabled ||
    isCookieSetToHideAppBanner ||
    isLoadedInAppView(query) ||
    !isMobile ||
    (isPaid && !isBranded) ||
    isBlackListed

  const showAppBanner = !hideAppBanner

  return showAppBanner
}

export default useAppBanner
